<template>
  <div class="main-menu menu-fixed menu-accordion menu-shadow" :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]" @mouseenter="updateMouseHovered(true)" @mouseleave="updateMouseHovered(false)">
    <!-- main menu header-->
    <div class="navbar-header expanded mb-1">
      <slot name="header" :toggleVerticalMenuActive="toggleVerticalMenuActive" :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon">
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link class="navbar-brand" to="/">
              <span class="brand-logo">
                <b-img :src="logo_siembal" alt="logo" width="40%" />
              </span>
              <h4 class="text-primary ml-1">
                <b class="mt-1">FLLAJ - Jateng</b>
              </h4> 
            
            </b-link>
            <small class="mb-2">Forum Lalu lintas & Angkutan Jalan </small>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle mt-1">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon icon="XIcon" size="20" class="d-block d-xl-none" @click="toggleVerticalMenuActive" />
              <feather-icon :icon="collapseTogglerIconFeather" size="20" class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed" />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{'d-block': shallShadowBottom}" class="shadow-bottom" />

    <!-- main menu content-->
    <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="main-menu-content scroll-area" tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }">
      <vertical-nav-menu-items :items="menuItemes" class="navigation navigation-main" />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
  import axios from '@/config/Axios'
  import navMenuItems from '@/navigation/vertical'
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import {
    BLink,
    BImg
  } from 'bootstrap-vue'
  import {
    provide,
    computed,
    ref
  } from '@vue/composition-api'
  import useAppConfig from '@core/app-config/useAppConfig'
  import {
    $themeConfig
  } from '@themeConfig'
  import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
  import useVerticalNavMenu from './useVerticalNavMenu'
  import Base from '@/config/Mixins_base';

  export default {
    components: {
      VuePerfectScrollbar,
      VerticalNavMenuItems,
      BLink,
      BImg,
    },
    mixins: [Base],
    data() {
      return {
        menuItemes: [],
        logo_siembal: require('@/assets/images/pages/logo_siembal_kc.png'),
      }
    },
    props: {
      isVerticalMenuActive: {
        type: Boolean,
        required: true,
      },
      toggleVerticalMenuActive: {
        type: Function,
        required: true,
      },
    },
    mounted() {
      // alert('teeee menuuuu');
      this.get_menu(); 
    },
    setup(props) {
      const {
        isMouseHovered,
        isVerticalMenuCollapsed,
        collapseTogglerIcon,
        toggleCollapsed,
        updateMouseHovered,
      } = useVerticalNavMenu(props)

      const {
        skin
      } = useAppConfig()

      // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
      const shallShadowBottom = ref(false)

      provide('isMouseHovered', isMouseHovered)

      const perfectScrollbarSettings = {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      }

      const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' :
        'DiscIcon'))

      // App Name
      const {
        appName,
        appLogoImage
      } = $themeConfig.app

      return {
        navMenuItems,
        perfectScrollbarSettings,
        isVerticalMenuCollapsed,
        collapseTogglerIcon,
        toggleCollapsed,
        isMouseHovered,
        updateMouseHovered,
        collapseTogglerIconFeather,

        // Shadow Bottom
        shallShadowBottom,

        // Skin
        skin,

        // App Name
        appName,
        appLogoImage,
      }
    },
    methods: {
      async get_menu() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/aut/sidebar',
            data: {

            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.menuItemes = response.data.result;
          }).catch(err => {
            self.pesan = err.message;
            console.lof(self.pesan);
          });
      },
    }
  }
</script>

<style lang="scss">
  @import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
